body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: #333333;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.boxp {
  height: 80%;
  width: 30%;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border-radius: 15px 15px; }
  .boxp > .screen {
    width: 100%;
    height: 20%;
    background-color: black;
    font-size: 5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
    text-shadow: 0 0 10px red;
    border-bottom: 1px solid red;
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px; }
    .boxp > .screen > .star {
      position: absolute;
      width: 50px;
      height: 5px;
      border-radius: 50%;
      top: 25.75%;
      background-color: red;
      -webkit-animation: slider 7s ease-in-out 1s infinite forwards;
              animation: slider 7s ease-in-out 1s infinite forwards; }
  .boxp > .btns {
    height: 15%;
    width: 25%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
    text-shadow: 0 0 10px red; }

@-webkit-keyframes slider {
  0% {
    left: 34.75%;
    opacity: 0; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    left: 62.25%;
    opacity: 0; } }

@keyframes slider {
  0% {
    left: 34.75%;
    opacity: 0; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    left: 62.25%;
    opacity: 0; } }

@media screen and (max-width: 414px) {
  .boxp {
    width: 100vw;
    height: 100vh; }
    .boxp > .screen > .star {
      top: 19.75%;
      -webkit-animation: sliderP 7s ease-in-out 1s infinite forwards;
              animation: sliderP 7s ease-in-out 1s infinite forwards; } }

@-webkit-keyframes sliderP {
  0% {
    left: -15%;
    opacity: 1; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes sliderP {
  0% {
    left: -15%;
    opacity: 1; }
  10% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

